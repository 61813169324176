<template>
  <div v-if="isActive" id="PopupUpdateServices" class="loading-wrapper">
    <Loader v-if="loading" id="page-loader"></Loader>
    <div :class="'loading-popup popup-' + type">
      <div class="h-100 m-5 align-items-center text-center m-0">
        <div class="row">
          <div class="col align-items-center">
            <!-- <div class="float-left">
                <img v-if="type == 'success'" class="icon" :src="require('@/assets/images/check-circle.png')" />
                <img v-if="type == 'danger'" class="icon" :src="require('@/assets/images/danger.png')" />
            </div> -->
            <div class="closeDelay" @click="closeDelayPopup">
              <font-awesome-icon :icon="['fa', 'times']" class="times"/>
            </div>

            <div class="text-center d-inline-block mb-5">
              <h2 class="popup-title">{{ title }}</h2>
              <hr>
              <div class="selector">
                <label class="typo__label">Veuillez selectionner un ou plusieur services</label>
             <!--   <multiselect v-model="values" :multiple="true" :options="options" :taggable="true"
                             label="title" placeholder="Chercher des services"></multiselect> -->
                <div v-for="item in options" :key="item.id">
                <input  type="checkbox" :id="item.id" :value="item.id" v-model="services">
                <label class="px-2" :for="item.id">{{item.title}}</label>
                <!--  <input style="width: 10%; text-align: center" type="number" value="0" v-model="quantity"/> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="hint" class="text-center mt-4">
         <!-- <span class="hint">{{ hint }}</span> -->
        </div>

        <button class="btn-confirm" @click="clicked()">{{ confirmationButton }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import {queueService} from '@/_services'
// import {privateServiceService, queueService} from '../../_services'
//import Multiselect from 'vue-multiselect'
import Loader from '../loader/Loader.vue'

export default {
  name: 'LoadingPopup',
  data: () => ({
    quantity: [],
    options: [],
    values: [],
    decrementButton: false,
    delay: {
      delay: 0,
      message: "",
      notify: true
    },
    loading: false
  }),
  props: {
    active: Boolean,
    title: String,
    message: String,
    hint: [Number, String],
    type: String,
    confirmationButton: String,
    services: Array
  },
  methods: {
    getSelectedServices() {
    },
    getAllServices() {
      queueService.getById(this.$route.params.id)
          .then(function (data) {
            console.log("get by id: " + data.services.length);
            data.services.map((el) => {
              let service = {"id": el.id, "title": el.title}
              this.options.push(service);
              //this.values.push(el.id);
            });
          }.bind(this));


    //  this.values = this.$props.servicesArray.slice();
   //   console.log("value : "+ this.servicesArray.length)
    },
    clicked() {
      /*this.quantity  = this.quantity.filter(function(value){
        return value > 0;
      });
      console.log("this.quantity")
      console.log(this.quantity)
      let quantityy = [0, 1, 2, 4, 3]
      quantityy.filter(function (value){return value>0;}) */
      this.values = [];
      this.services.forEach((el) => {
        console.log("element")
       console.log(el+"-qte:")
        this.values.push(el+"-qte:")
      });
      /* let newValue =[]
      for(let i = 0; i< this.values.length; i++){
         newValue.push(this.values[i]+quantityy[i]);
      }
      let payload = {"services" : newValue.join(",").toString()};*/
      let payload = {"services" : this.values.join(",").toString()};
     // console.log(newValue);


      queueService.updateServices(this.$route.params.id, this.$props.hint, payload).then(function (data) {
        console.log(data);
      }).finally(function () {
        this.$route.go();
      }.bind(this));
    },
    closeDelayPopup() {
      this.$emit('close')
    }
  },
  computed: {
    isActive() {
      return this.active;
    }
  },
  mounted() {
    // this.getAllServices();
  },
  components: {
    Loader,
  }
}
</script>

<style lang="scss" scoped>

.loading-wrapper {
  background: rgba(0, 0, 0, 0.70);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.loading-popup {
  width: 60%;
  max-width: 500px;
  min-width: 300px;
  background: #ffffff;
  border-radius: 20px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  color: #264076;
}

.loader {
  width: 50px;
  height: 50px;
}

.popup-success .popup-title {
  color: #bed24c !important;
  margin-bottom: 0px;
  font-size: 20px !important;
  display: flex;
}

.popup-success .icon {
  width: 70px;
  margin: 10px 10px;
}

.popup-success .message {
  color: #444;
  font-weight: lighter;
  font-size: 18px;
}

.popup-success .hint {
  color: #333;
  font-weight: bold;
  font-size: 16px;
}

.popup-success .btn-confirm {
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 0px 0px 20px 20px;
  padding: 15px;
  width: 100%;
  border: none;
  background: #bed24c;
  color: #fff;
  font-weight: 400;
  font-size: 22px;
}

.popup-success .btn-confirm:not(:disabled):not(.disabled):active, .btn-confirm:not(:disabled):not(.disabled).active, .show > .btn-confirm.dropdown-toggle {
  color: #fff;
  background-color: #bed24c;
  border-color: #bed24c;
}

.popup-success .btn-confirm:focus, .btn-confirm.focus {
  box-shadow: none;
}

.popup-danger .popup-title {
  color: #DC3545 !important;
  margin-bottom: 0px;
  font-size: 42px !important;
  display: flex;
}

.popup-danger .icon {
  width: 70px;
  margin: 10px 10px;
}

.popup-danger .message {
  color: #444;
  font-weight: lighter;
  font-size: 18px;
}

.popup-danger .hint {
  color: #333;
  font-weight: bold;
  font-size: 16px;
}

.popup-danger .btn-confirm {
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 0px 0px 20px 20px;
  padding: 15px;
  width: 100%;
  border: none;
  background: #DC3545;
  color: #fff;
  font-weight: 400;
  font-size: 22px;
}

.popup-danger .btn-confirm:not(:disabled):not(.disabled):active, .btn-confirm:not(:disabled):not(.disabled).active, .show > .btn-confirm.dropdown-toggle {
  color: #fff;
  background-color: #DC3545;
  border-color: #DC3545;
}

.popup-danger .btn-confirm:focus, .btn-confirm.focus {
  box-shadow: none;
}

.delay {
  padding: 30px 0;
  margin-top: -16px;
}

.delay .time {
  width: 100%;
  height: 50px;
  border: 1px solid #264076;
  padding: 0 10px;
  border-radius: 10px;
}

.delay .checkbox {
  display: inline-block;
  margin: 20px 0;
}

.delay textarea {
  display: block;
  height: 70px;
  width: 100%;
  border: 1px solid #264076;
  padding: 0 10px;
  border-radius: 10px;
}

.closeDelay {
  float: right;
  font-size: 17px;
  color: #bed24c;
  padding: 5px 10px;
  border: 1px solid #bed24c;
  border-radius: 50px;
  transition: .3s;
  position: absolute;
  right: 0;
}

.closeDelay:hover {
  color: #fff;
  background-color: #bed24c;
}
</style>
<template>
    <div id="PopupForwardTo" class="loading-wrapper" v-if="isActive">
        <Loader id="page-loader" v-if="loading"></Loader>
        <div :class="'loading-popup popup-' + type">
            <div class="h-100 m-5 align-items-center text-center m-0">
                <div class="row">
                    <div class="col align-items-center">
                        <!-- <div class="float-left">
                            <img v-if="type == 'success'" class="icon" :src="require('@/assets/images/check-circle.png')" />
                            <img v-if="type == 'danger'" class="icon" :src="require('@/assets/images/danger.png')" />
                        </div> -->
                        <div class="closeDelay" @click="closeDelayPopup">
                            <font-awesome-icon class="times" :icon="['fa', 'times']" />
                        </div>
                        
                        <div class="text-center d-inline-block mb-5">
                            <h2 class="popup-title">{{title}}</h2>
                            <hr>
                              <div class="selector">
                                <label class="typo__label">Veuillez selectionner un guichet</label>
                                <multiselect v-model="value" :options="options" placeholder="Select one" label="name" track-by="name" ></multiselect>
                              </div>
                        </div>
                    </div>
                </div>

                <div class="text-center mt-4" v-if="hint">
                    <span class="hint">{{hint}}</span>
                </div>

                <button class="btn-confirm" @click="clicked()">{{confirmationButton}}</button>
            </div>
        </div>
    </div>
</template>

<script>
     import { queueService } from '../../_services'
    // import { privateServiceService } from '../../_services'
    import Multiselect from 'vue-multiselect'
    import Loader from '../loader/Loader.vue'

    export default {
        name: 'LoadingPopup',
        data:()=>({
          value: [

          ],
          options: [
            { name: 'Vue.js', code: 'vu' },
            { name: 'Javascript', code: 'js' },
            { name: 'Open Source', code: 'os' },
            { name: 'Vue.js', code: 'vu1' },
            { name: 'Javascript', code: 'js1' },
            { name: 'Open Source', code: 'os1' }, { name: 'Vue.js', code: 'vu2' },
            { name: 'Javascript', code: 'js2' },
            { name: 'Open Source', code: 'os2' }, { name: 'Vue.js', code: 'vu2' },
            { name: 'Javascript', code: 'js2' },
            { name: 'Open Source', code: 'os2' }, { name: 'Vue.js', code: 'vu3' },
            { name: 'Javascript', code: 'js4' },
            { name: 'Open Source', code: 'os4' },
          ],
          decrementButton: false,
          delay: {
                delay: 0,
                message: "",
                notify: true
            },
            loading: false
        }),
        props: {
            active: Boolean,
            title: String,
            message: String,
            hint: String,
            type: String,
            confirmationButton: String
        },
        methods: {
          decrement(){
            if (this.delay.delay === 0) {this.decrementButton = true;
              alert("Negative minutes not allowed"); }
             else {this.delay.delay--;
             }
          },
          increment(){
            this.delay.delay ++;
            this.decrementButton = false;
          },
          getCounters(){
queueService.getById(this.$route.params.id)
    .then(function (data){
      console.log("+"+data);

    });
          },
            clicked(){
              /*  const self= this
                this.loading= true
                console.log(this.delay)
                queueService.addDelay(this.$route.params.id, this.delay).then(function(data){
                    console.log(data)
                }).finally(function(){
                    self.$router.go();
                }) */
            },
            closeDelayPopup(){
                this.$emit('close')
            }
        },
      mounted() {
        //   this.getCounters();
      },
      computed: {
            isActive(){
                return this.active;
            }
        },
        components: {
            Loader,
          Multiselect,
        }
    }
</script>

<style lang="scss" scoped>

    .loading-wrapper{
        background: rgba(0, 0, 0, 0.70);
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 1000;
    }

    .loading-popup{
        width: 60%;
        max-width: 500px;
        min-width: 300px;
        background: #ffffff;
        border-radius: 20px;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        color: #264076;
    }

    .loader{
        width: 50px;
        height: 50px;
    }

    .popup-success .popup-title {
        color: #bed24c !important;
        margin-bottom: 0px;
        font-size: 20px !important;
        display: flex;
    }

    .popup-success  .icon {
        width: 70px;
        margin: 10px 10px;
    }

    .popup-success  .message {
        color: #444;
        font-weight: lighter;
        font-size: 18px;
    }

    .popup-success  .hint {
        color: #333;
        font-weight: bold;
        font-size: 16px;
    }

    .popup-success .btn-confirm {
        position: absolute;
        bottom: 0;
        left: 0;
        border-radius: 0px 0px 20px 20px;
        padding: 15px;
        width: 100%;
        border: none;
        background: #bed24c;
        color: #fff;
        font-weight: 400;
        font-size: 22px;
    }

    .popup-success .btn-confirm:not(:disabled):not(.disabled):active, .btn-confirm:not(:disabled):not(.disabled).active, .show > .btn-confirm.dropdown-toggle {
        color: #fff;
        background-color: #bed24c;
        border-color: #bed24c;
    }

    .popup-success .btn-confirm:focus, .btn-confirm.focus {
        box-shadow: none;
    }

    .popup-danger .popup-title {
        color: #DC3545 !important;
        margin-bottom: 0px;
        font-size: 42px !important;
        display: flex;
    }

    .popup-danger  .icon {
        width: 70px;
        margin: 10px 10px;
    }

    .popup-danger  .message {
        color: #444;
        font-weight: lighter;
        font-size: 18px;
    }

    .popup-danger  .hint {
        color: #333;
        font-weight: bold;
        font-size: 16px;
    }

    .popup-danger .btn-confirm {
        position: absolute;
        bottom: 0;
        left: 0;
        border-radius: 0px 0px 20px 20px;
        padding: 15px;
        width: 100%;
        border: none;
        background: #DC3545;
        color: #fff;
        font-weight: 400;
        font-size: 22px;
    }

    .popup-danger .btn-confirm:not(:disabled):not(.disabled):active, .btn-confirm:not(:disabled):not(.disabled).active, .show > .btn-confirm.dropdown-toggle {
        color: #fff;
        background-color: #DC3545;
        border-color: #DC3545;
    }

    .popup-danger .btn-confirm:focus, .btn-confirm.focus {
        box-shadow: none;
    }
    .delay{
        padding: 30px 0;
        margin-top: -16px;
    }
    .delay .time{
        width: 100%;
        height: 50px;
        border: 1px solid #264076;
        padding: 0 10px;
        border-radius: 10px;
    }
    .delay .checkbox{
        display: inline-block;
        margin: 20px 0;
    }
    .delay textarea{
        display: block;
        height: 70px;
        width: 100%;
        border: 1px solid #264076;
        padding: 0 10px;
        border-radius: 10px;
    }
    .closeDelay{
        float: right;
        font-size: 17px;
        color: #bed24c;
        padding: 5px 10px;
        border: 1px solid #bed24c;
        border-radius: 50px;
        transition: .3s;
        position: absolute;
        right: 0;
    }
    .closeDelay:hover{
        color: #fff;
        background-color: #bed24c;
    }
</style>
<template>
  <div id="waitinglist">
    <vue-confirm-dialog></vue-confirm-dialog>
    <Window
      id="options-window"
      ref="windowComponent"
      :delay="selected_queue.delay"
      :requests="requests"
      @resumeDelay="resumeDelay"
    ></Window>
    <Loader v-if="loading || callnext_loading" id="page-loader"></Loader>
    <div v-if="landscape" class="row p-0 m-0 landscape">
      <div class="col-6 first-side p-0">
        <div v-if="selected_queue.id == undefined" class="row right-logo">
          <div class="col-3"></div>
          <div class="col-6 logo">
            <img
              :src="require('@/assets/images/Logo_Original@2x.png')"
              alt=""
            />
          </div>
        </div>
        <div v-else class="header" style="padding: 5px 10px">
          <div class="row p-0 m-0">
            <div class="col-8 left">
              <div class="row p-0">
                <div class="col-8 p-0" @click="closeCounter()">
                  <b> {{ selected_queue.title }}</b>
                  <small
                    >{{$t("Teller.Counter") }}:
                    {{
                      selected_counter.name ? selected_counter.name : "--"
                    }}</small
                  >
                </div>
              </div>
            </div>
            <!-- NOTIFICATION BUTTON -->
            <!--
                 <div class="col-3 right">
                   <div class="row p-0" style="text-align: right;">
                     <div class="col-5 p-0 notif" @click="openRequestsPopup">
                       <font-awesome-icon :icon="['fas', 'bell']" class="bell"/>
                       <span v-if="selected_queue.pending>0">{{ selected_queue.pending }}</span>
                     </div>
                   </div>
                 </div> -->
          </div>
        </div>
        <div class="row">
          <div class="col-1"></div>
          <!-- <div v-if="loading"  class="col-10 ticket">
              <img  :src="require('@/assets/images/loader-circle.gif')" alt=""  style="width: 30%;margin-top: 20%;"/>
          </div> -->
          <div
            v-if="!loading && selected_queue.id != undefined"
            class="col-10 ticket"
          >
            <div v-if="!serving_ticket">
              <div class="box-empty">
                <img :src="require('@/assets/images/2039107.png')" alt="" />
                <hr />
                <small class="signature"
                  >{{ $t('Teller.QueueEmpty') }}</small
                >
              </div>
            </div>
            <div
              v-else
              :class="
                serving_ticket && serving_ticket.status
                  ? 'status-' + serving_ticket.status
                  : 'status-waiting'
              "
            >
              <b
                v-if="serving_ticket && serving_ticket.status == 'waiting'"
                class="guide-button"
                style="color: #000; font-size: 3vh"
                >{{$t('Teller.ClickNext')}}<br /><br />
              </b>
              <div
                class="row justify-content-center mb-4"
                v-if="serving_ticket && serving_ticket.status != 'waiting'"
              >
                <!--- Action buttons --->
                <!-- <b-button class="p-2 m-2"  pill
                          style="background: #008fca; color: #fff; font-size: 1em" variant="primary"
                     @click="showAlert('êtes-vous sûr de vouloir mettre ce ticket en attente?', 'onHold', null)">
                  <i class="bi bi-pause-circle"></i>
                  <span class="pl-2">Pauser le ticket</span>
                </b-button>
                  <b-button class="p-2 m-2"  pill
                           style="background: #008fca; color: #fff; font-size: 1em" variant="primary"
                      @click="UpdateServicePopup()">
                   <i class="bi bi-pencil-square"></i>
                   <span class="pl-2">Edit service</span>
                 </b-button>
      <b-button class="p-2 m-2"  pill
                           style="background: #008fca; color: #fff; font-size: 1em" variant="primary"
                      @click="ShowPopupForwardTo()">
                   <i class="bi bi-send"></i>    <span class="pl-2">transferer</span>
                 </b-button> -->
                <b-button
                  class="p-2 m-2"
                  pill
                  style="background: #008fca; color: #fff; font-size: 1em"
                  variant="primary"
                  @click="addDelay()"
                >
                  <i class="bi bi-hourglass-split"></i>
                  <span class="pl-2">{{ $t('Teller.ChangeTimeService') }}</span>
                </b-button>
              </div>

              <div
                :style="
                  'background-color:' +
                    (serving_ticket && serving_ticket.running_late
                      ? 'yellow;color: #000;'
                      : '')
                "
                class="box1"
              >
                <div
                  v-if="serving_ticket.status == 'inservice'"
                  class="voice-teller"
                  @click="
                    voiceTeller(
                      serving_ticket.ticket_id
                        ? serving_ticket.ticket_id
                        : serving_ticket.id
                    )
                  "
                >
                  <font-awesome-icon
                    :icon="['fas', 'volume-up']"
                    class="volume"
                  />
                </div>
                <div
                  v-if="serving_ticket.status == 'inservice'"
                  class="redirect-teller"
                  @click="
                    redirectTicket()
                  "
                >
                  <img
                    :src="require('@/assets/images/next.png')"
                    alt=""
                    style="width: 22px; height: 24px"
                  />
                </div>

                <span>{{ $t('Teller.TicketNumber') }}</span><br />
                <b>{{ serving_ticket.public_identifier }}</b
                ><br />
                <small
                  v-if="serving_ticket && serving_ticket.running_late"
                  style="color: red"
                  >{{ $t('Teller.Late') }}</small
                >

                <div class="circle-left"></div>
                <div class="circle-right"></div>
              </div>

              <div class="box2">
                <span class="name"
                  >{{
                    serving_ticket.fname
                      ? serving_ticket.fname
                      : serving_ticket.customer
                      ? serving_ticket.customer.fname
                      : ""
                  }}
                  {{
                    serving_ticket.lname
                      ? serving_ticket.lname
                      : serving_ticket.customer
                      ? serving_ticket.customer.lname
                      : ""
                  }}</span
                >
                <div
                  v-for="(service, index) in serving_ticket.services"
                  :key="index"
                >
                  <small class="signature">{{
                    service.title + " ( x" + service.qte + " ) "
                  }}</small>
                </div>
                <div class="date">
                  {{ serving_ticket.checkinDate }}
                  {{ serving_ticket.checkinTime }}
                </div>
              </div>
            </div>
          </div>
          <div v-else class="col-10 queues mb-10">
            <div v-for="item in queues" :key="item.queue.id">
              <div
                :class="'box1 queue-item queue-item-' + item.queue.id"
                @click="
                  select_queue(
                    item.queue.id,
                    item.queue.counters.length,
                    item.queue.counters
                  )
                "
              >
                <span>{{ item.queue.title }}</span>
                <hr />
                <div class="row">
                  <div class="col-6 left" style="text-align: right">
                    <small>{{ $t('Teller.Waiting') }}</small><b> {{ item.total_waiting }} </b>
                  </div>
                  <div class="col-6" style="text-align: left">
                    <b v-if="getHours(item.waiting_time) > 0">
                      {{ getHours(item.waiting_time) }} <small>{{ $t("Teller.Hr") }}</small></b
                    >
                    <b> {{ getMinutes(item.waiting_time) }} </b>
                    <small>{{ $t('Teller.Hr') }}</small>
                  </div>
                </div>
              </div>
              <div
                v-if="item.queue.counters.length > 0"
                :class="'counters counters-' + item.queue.id"
                :style="queues.length <= 1 ? 'display: block;' : ''"
              >
                <select id="" v-model="selected_counter" class="counter-select">
                  <option value="">{{ $t('Teller.ChooseCounter') }}</option>
                  <option
                    v-for="counter in item.queue.counters"
                    :key="counter.id"
                    :value="counter.id"
                  >
                    {{ counter.name }}
                  </option>
                </select>
                <div v-if="selected_counter != ''" class="row mt-2">
                  <div class="col-6">
                    <button
                      class="btn btn-danger close-counter"
                      @click="closeCounter(selected_counter, item)"
                    >
                      {{$t('Teller.Close')}}
                    </button>
                  </div>
                  <div class="col-6">
                    <button
                      class="btn btn-success confirm-counter"
                      @click="confirmCounter(item)"
                    >
                    {{$t('Teller.Open')}}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="
            business_config.ticket_price_status == 1 &&
              !loading &&
              selected_queue.id != undefined
          "
          class="row d-flex justify-content-center mt-3"
        >
          <div
            class="col-10 ticket-amount-card"
            v-if="serving_ticket && serving_ticket.status != 'waiting'"
          >
            <div style="display: flex; padding: 5px;">
              <input
                type="number"
                class="amount-input"
                :placeholder="$t('Teller.Amount')"
                v-model="amountTicket"
                min="0"
                step="100"
              />
              <select class="amount-type" v-model="selectedPaymentType">
                <option value="null" disabled>{{$t('Teller.PaymentType')}}</option>
                  <option
                    :key="index"
                    :value="item.id"
                    v-for="(item, index) in paymentTypes"
                  >
                  {{item.title}}
                  </option>
                </select>
            </div>
            <button class="approve-button" @click="updateTicketPrice">
              <b>{{ $t('Teller.ConfirmButton') }}</b>
            </button>
          </div>
          <div
            class="col-10 user-info-card line"
            v-if="serving_ticket && serving_ticket.status != 'waiting'"
          ></div>
          <div class="col-10 ticket-amount-total">
            <div style="display: flex; padding: 5px;">
              <div v-if="amountTicketSent">
                {{ $t('Teller.TicketPrice') }}: {{ amountTicketSent }}
              </div>
            </div>
            <div style="display: flex; padding: 5px;">
              {{ $t('Teller.Total') }}:
              <div class="stat-numbers" v-if="counterAgentStats">
                {{
                  counterAgentStats.totalAmount
                    ? counterAgentStats.totalAmount
                    : 0
                }}
              </div>
            </div>
          </div>
        </div>

        <!-- <div
          v-if="!loading && selected_queue.id != undefined"
          class="row d-flex justify-content-center mt-3"
        >
          <div class="col-10 user-info-card">
            <div style="display: flex; padding: 5px;">
              Feedback positive:
              <div class="stat-total" style="background-color: green;">
                {{ counterAgentStats.feedbackPositive }}
              </div>
            </div>
            <div style="display: flex; padding: 5px;">
              Feedback negative:
              <div class="stat-numbers" style="background-color: red;">
                {{ counterAgentStats.feedbackNegative }}
              </div>
            </div>
          </div>
          <div class="col-10 user-info-card line"></div>
          <div class="col-10 user-info-card">
            <div style="padding: 5px; width: 100%;">
              Feedback par niveau:
              <div
                class="user-star"
                v-if="
                  counterAgentStats &&
                    counterAgentStats.stars &&
                    counterAgentStats.stars.data
                "
              >
                <div
                  v-for="(item, index) in counterAgentStats.stars.data.length"
                  :key="index"
                  style="margin: 0 5px;"
                >
                  {{ item }} etoiles: {{ counterAgentStats.stars.data[index] }}
                </div>
              </div>
            </div>
          </div>
        </div> -->

        <div v-if="selected_queue.id != undefined" class="footer">
          <br />
          <div class="p-2" style="text-align: center">
            <h5 v-if="ticketsOnHold.length != 0" style="color: #ffc107">
              Tickets en stand by
            </h5>
            <h6 v-if="ticketsOnHold.length != 0" style="color: #6c757d">
              Cliquer pour rappeller un ticket..
            </h6>
          </div>
          <div
            v-if="ticketsOnHold.length != 0"
            class="row d-flex justify-content-center"
          >
            <b-button
              v-for="item in ticketsOnHold"
              :key="item.ticket_id"
              class="p-2 mx-2"
              style="color: #fff; font-size: 1.5em"
              variant="warning"
              @click="
                showAlert(
                  'êtes-vous sûr de vouloir rappeler ce ticket?',
                  'recallOnHold',
                  item.ticket_id
                )
              "
            >
              {{ item.public_identifier }}
            </b-button>
          </div>
          <div class="col-6 logo">
            <img
              :src="require('@/assets/images/Logo_Original@2x.png')"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="col-3 left-side left-side p-0">
        <button class="btn">
          <small>{{ $t('Teller.Waiting') }}: </small><br />
          <b>{{ selected_queue.totalWaiting }}</b>
        </button>
        <button
          :style="'color: ' + (selected_queue.delay ? '#ffff4d' : '#fff')"
          class="btn"
        >
          <small>{{ $t('Teller.WaitingTime') }}: <br /></small>
          <b>
            <span v-if="selected_queue.waiting_hours > 0"
              >{{ selected_queue.waiting_hours }} <small>{{ $t('Teller.Hr') }}</small>
            </span>
            <span
              v-if="
                selected_queue.waiting_minutes > 0 ||
                  selected_queue.waiting_seconds > 0
              "
              >&nbsp;{{ selected_queue.waiting_minutes }} <small>{{ $t('Teller.Min') }}</small>
            </span>
            <span v-if="selected_queue.waiting_seconds > 0">
              &nbsp; <small>{{ selected_queue.waiting_seconds }} {{ $t('Teller.Sec') }}</small>
            </span>
            <span
              v-if="
                selected_queue.waiting_minutes == 0 &&
                  selected_queue.waiting_hours == 0 &&
                  selected_queue.waiting_seconds == 0
              "
              >--</span
            >
          </b>
          <!-- <b style="color: #ffff4d" v-if="selected_queue.delay">
              <br>
              <span v-if="selected_queue.delay_hours>0">{{ selected_queue.delay_hours }} <small>hr</small> </span>
              <span v-if="selected_queue.delay_minutes>0">&nbsp;{{ selected_queue.delay_minutes }}  <small>min</small> </span>
              <span v-if="selected_queue.delay_secondes>0"> &nbsp; <small>{{ selected_queue.delay_secondes }} s</small> </span>
              <span v-if="selected_queue.delay_minutes==0 && selected_queue.delay_hours==0 && selected_queue.delay_secondes==0">--</span>
          </b>  -->
          <small style="display: none"
            >{{ selected_queue.delay }} {{ selected_queue.waiting_time }}</small
          >
          <span
            v-if="client_took_more_than_he_should"
            class="badge badge-danger late-danger"
            >!</span
          >
        </button>
        <button class="btn">
          <small>{{$t('Teller.ServedToday')}}: </small><br />
          <b>{{
            counterAgentStats.ticketServedToday
              ? counterAgentStats.ticketServedToday
              : "--"
          }}</b>
        </button>
        <button class="btn">
          <small>{{ $t('Teller.ServedMonth') }}: </small><br />
          <b
            >{{
              counterAgentStats.ticketServedMonth
                ? counterAgentStats.ticketServedMonth
                : "--"
            }}
            <!--small>demandes</small--></b
          >
        </button>
      </div>
      <div class="col-3 right-side p-0">
        <button class="btn btn-options" @click="optionsButton">
          <span>{{ $t('Teller.Options') }}</span>
          <img :src="require('@/assets/images/cog.png')" alt="" class="icon" />
        </button>
        <button
          v-if="business_config.ticket_price_status == 1"
          :disabled="selected_queue.id == undefined || !serving_ticket"
          class="btn btn-suivant"
          @click="callNextWithPrice"
        >
          <span>{{ $t('Teller.Next') }}</span>
          <img :src="require('@/assets/images/next.png')" alt="" class="icon" />
          <!-- <img class="icon" :src="require('@/assets/images/loader-circle.gif')" alt=""/> -->
        </button>
        <button
          v-else
          :disabled="selected_queue.id == undefined || !serving_ticket"
          class="btn btn-suivant"
          @click="nextButton"
        >
          <span>{{ $t('Teller.Next') }}</span>
          <img :src="require('@/assets/images/next.png')" alt="" class="icon" />
          <!-- <img class="icon" :src="require('@/assets/images/loader-circle.gif')" alt=""/> -->
        </button>
        <button
          :disabled="
            selected_queue.id == undefined ||
              !serving_ticket ||
              serving_ticket.status != 'inservice'
          "
          class="btn btn-absent"
          @click="absentButton"
        >
          <span>{{ $t('Teller.Absent') }}</span>
          <img
            :src="require('@/assets/images/close.png')"
            alt=""
            class="icon"
          />
        </button>
        <button
          :disabled="
            selected_queue.id == undefined ||
              !serving_ticket ||
              serving_ticket.status != 'inservice'
          "
          class="btn btn-fermer"
          @click="fermerButton"
        >
          <span>{{ $t('Teller.Close') }}</span>
          <img
            :src="require('@/assets/images/close.png')"
            alt=""
            class="icon"
          />
        </button>
        <button class="btn btn-actualiser" @click="refreshButton">
          <span>{{ $t('Teller.Refresh') }}</span>
          <img
            :src="require('@/assets/images/update.png')"
            alt=""
            class="icon"
          />
        </button>
      </div>
    </div>

    <!-- Portrait shape -->
    <div v-else class="row p-0 m-0 portrait">
      <div class="col-12 first-side p-0">
        <div v-if="selected_queue.id == undefined" class="row right-logo">
          <div class="col-3"></div>
          <div class="col-6 logo">
            <img
              :src="require('@/assets/images/Logo_Original@2x.png')"
              alt=""
            />
          </div>
        </div>
        <div v-else class="header" style="padding: 5px 10px">
          <div class="row p-0 m-0">
            <div class="col-10 left">
              <div class="row p-0" style="text-align: left">
                <!-- <div class="col-2  p-0">
                    <img :src="user.photo" alt="" v-if="user.photo!=''">
                    <img :src="require('@/assets/images/1.jpg')" alt="" v-else>
                </div> -->
                <div class="col-12 p-0" @click="closeCounter">
                  <b> {{ selected_queue.title }}</b>
                  <small
                    >{{ $t('Teller.Counter') }}:
                    {{
                      selected_counter.name ? selected_counter.name : "--"
                    }}</small
                  >
                </div>
              </div>
            </div>
            <!-- NOTIFICATION BUTTON -->
            <!--     <div class="col-2 right">
              <div class="row p-0" style="text-align: right;" @click="openRequestsPopup">
                <div class="col-2 p-0"></div>
                <div class="col-10 p-0 notif">
                  <font-awesome-icon :icon="['fas', 'bell']" class="bell"/>
                  <span v-if="selected_queue.pending>0">{{ selected_queue.pending }}</span>
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <div class="row">
          <div class="col-1"></div>
          <!-- <div v-if="loading"  class="col-10 ticket">
              <img  :src="require('@/assets/images/loader-circle.gif')" alt=""  style="width: 30%;margin-top: 20%;"/>
          </div> -->
          <div
            v-if="!loading && selected_queue.id != undefined"
            class="col-10 ticket"
          >
            <div v-if="!serving_ticket">
              <div class="box-empty">
                <img :src="require('@/assets/images/2039107.png')" alt="" />
                <hr />
                <small class="signature"
                  >{{ $t('Teller.QueueEmpty') }}</small
                >
              </div>
            </div>
            <div
              v-else
              :class="
                serving_ticket && serving_ticket.status
                  ? 'status-' + serving_ticket.status
                  : 'status-waiting'
              "
            >
              <b
                v-if="serving_ticket && serving_ticket.status == 'waiting'"
                class="guide-button"
                style="color: #000; font-size: 3vh"
                >{{ $t('Teller.ClickNext') }}<br /><br />
              </b>
              <div
                :style="
                  'background-color:' +
                    (serving_ticket && serving_ticket.running_late
                      ? 'yellow;color: #000;'
                      : '')
                "
                class="box1"
              >
                <div
                  v-if="serving_ticket.status == 'inservice'"
                  class="voice-teller"
                  @click="
                    voiceTeller(
                      serving_ticket.ticket_id
                        ? serving_ticket.ticket_id
                        : serving_ticket.id
                    )
                  "
                >
                  <font-awesome-icon
                    :icon="['fas', 'volume-up']"
                    class="volume"
                  />
                </div>
                <span>{{ $t('Teller.TicketNumber') }}</span><br />
                <b>{{ serving_ticket.public_identifier }}</b
                ><br />

                <small
                  v-if="serving_ticket && serving_ticket.running_late"
                  style="color: red"
                  >{{ $t('Teller.Late') }}</small
                >
                <div class="circle-left"></div>
                <div class="circle-right"></div>
              </div>
              <div class="box2">
                <span class="name"
                  >{{
                    serving_ticket.fname
                      ? serving_ticket.fname
                      : serving_ticket.customer
                      ? serving_ticket.customer.fname
                      : ""
                  }}
                  {{
                    serving_ticket.lname
                      ? serving_ticket.lname
                      : serving_ticket.customer
                      ? serving_ticket.customer.lname
                      : ""
                  }}</span
                >
                <small class="signature">{{
                  serving_ticket.service_names
                }}</small>

                <div class="date">
                  {{ serving_ticket.checkinDate }}
                  {{ serving_ticket.checkinTime }}
                </div>
              </div>
            </div>
          </div>
          <div v-else class="col-10 queues mb-10">
            <div v-for="item in queues" :key="item.queue.id">
              <div
                :class="'box1 queue-item queue-item-' + item.queue.id"
                @click="
                  select_queue(
                    item.queue.id,
                    item.queue.counters.length,
                    item.queue.counters
                  )
                "
              >
                <span>{{ item.queue.title }}</span>
                <hr />
                <div class="row">
                  <div class="col-6 left" style="text-align: right">
                    <small> En attente</small><b> {{ item.total_waiting }} </b>
                  </div>
                  <div class="col-6" style="text-align: left">
                    <b v-if="getHours(item.waiting_time) > 0">
                      {{ getHours(item.waiting_time) }} <small>{{ $t('Teller.Hr') }}</small></b
                    >
                    <b> {{ getMinutes(item.waiting_time) }} </b>
                    <small>{{ $t('Teller.Min') }}</small>
                  </div>
                </div>
              </div>
              <div
                v-if="item.queue.counters.length > 0"
                :class="'counters counters-' + item.queue.id"
              >
                <select id="" v-model="selected_counter" class="counter-select">
                  <option value="">{{ $t('Teller.ChooseCounter') }}</option>
                  <option
                    v-for="counter in item.queue.counters"
                    :key="counter.id"
                    :value="counter.id"
                  >
                    {{ counter.name }}
                  </option>
                </select>
                <div v-if="selected_counter != ''" class="row mt-2">
                  <div class="col-6">
                    <button
                      class="btn btn-danger close-counter"
                      @click="closeCounter(selected_counter, item)"
                    >
                    {{ $t('Teller.Close') }}
                    </button>
                  </div>
                  <div class="col-6">
                    <button
                      class="btn btn-success confirm-counter"
                      @click="confirmCounter(item)"
                    >
                    {{ $t('Teller.Open') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <br />
            <br />
          </div>
        </div>

        <div
          v-if="selected_queue.id != undefined"
          class="row footer footer-landscape"
        >
          <div class="col-3"></div>
          <div class="col-6 logo">
            <img
              :src="require('@/assets/images/Logo_Original@2x.png')"
              alt=""
            />
          </div>
        </div>
      </div>

      <div class="col-12 row left-side p-0 m-0">
        <button class="btn col-6">
          <small>{{ $t('Teller.Waiting') }}: </small><br />
          <b>{{ selected_queue.totalWaiting }}</b>
        </button>
        <button
          :style="
            'color: ' +
              (selected_queue.delay ? '#ffff4d' : '#fff') +
              ';border-right: none !important;'
          "
          class="btn col-6"
        >
          <small>{{ $t('Teller.WaitingTime') }}: <br /></small>
          <b>
            <span v-if="selected_queue.waiting_hours > 0"
              >{{ selected_queue.waiting_hours }} <small>{{ $t('Teller.Hr') }}</small>
            </span>
            <span
              v-if="
                selected_queue.waiting_minutes > 0 ||
                  selected_queue.waiting_seconds > 0
              "
              >&nbsp;{{ selected_queue.waiting_minutes }} <small>{{ $t('Teller.Min') }}</small>
            </span>
            <span v-if="selected_queue.waiting_seconds > 0">
              &nbsp; <small>{{ selected_queue.waiting_seconds }} s</small>
            </span>
            <span
              v-if="
                selected_queue.waiting_minutes == 0 &&
                  selected_queue.waiting_hours == 0 &&
                  selected_queue.waiting_seconds == 0
              "
              >--</span
            >
          </b>
          <!-- <b style="color: #ffff4d" v-if="selected_queue.delay">
              <br>
              <span v-if="selected_queue.delay_hours>0">{{ selected_queue.delay_hours }} <small>hr</small> </span>
              <span v-if="selected_queue.delay_minutes>0">&nbsp;{{ selected_queue.delay_minutes }}  <small>min</small> </span>
              <span v-if="selected_queue.delay_secondes>0"> &nbsp; <small>{{ selected_queue.delay_secondes }} s</small> </span>
              <span v-if="selected_queue.delay_minutes==0 && selected_queue.delay_hours==0 && selected_queue.delay_secondes==0">--</span>
          </b>  -->
          <small style="display: none"
            >{{ selected_queue.delay }} {{ selected_queue.waiting_time }}</small
          >
        </button>
        <button class="btn col-6" style="border-left: none !important">
          <small>{{ $t('Teller.ServedToday') }}: </small><br />
          <b>{{
            counterAgentStats.ticketServedToday
              ? counterAgentStats.ticketServedToday
              : "--"
          }}</b>
        </button>
        <button class="btn col-6">
          <small>{{ $t('Teller.ServedMonth') }}: </small><br />
          <b
            >{{
              counterAgentStats.ticketServedMonth
                ? counterAgentStats.ticketServedMonth
                : "--"
            }}
            <!--small>demandes</small--></b
          >
        </button>
      </div>
      <div class="col-12 row right-side p-0 m-0">
        <button class="btn col btn-actualiser" @click="refreshButton">
          <img
            :src="require('@/assets/images/update.png')"
            alt=""
            style="width: 50%"
          />
        </button>
        <button
          :disabled="
            selected_queue.id == undefined ||
              !serving_ticket ||
              serving_ticket.status != 'inservice'
          "
          class="btn col btn-absent"
          @click="absentButton"
        >
          <img
            :src="require('@/assets/images/close.png')"
            alt=""
            style="width: 50%"
          />
        </button>
        <button
          :disabled="selected_queue.id == undefined || !serving_ticket"
          class="btn col btn-suivant"
          @click="nextButton"
        >
          <img
            :src="require('@/assets/images/next.png')"
            alt=""
            style="width: 50%"
          />
          <!-- <img class="icon" :src="require('@/assets/images/loader-circle.gif')" alt=""/> -->
        </button>
        <button class="btn col btn-options" @click="optionsButton">
          <img
            :src="require('@/assets/images/cog.png')"
            alt=""
            style="width: 50%"
          />
        </button>
      </div>
    </div>

    <PopupAddDelay
      :active.sync="popup.active"
      :confirmationButton="popup.confirmation"
      :hint="popup.hint"
      :message="popup.message"
      :title="popup.title"
      :ticket="serving_ticket"
      :type="popup.type"
      @close="hidePopup()"
      @confirm="popup.callback ? popup.callback : hidePopup()"
    >
    </PopupAddDelay>
    <PopupUpdateServices
      :active.sync="popup_update_services.active"
      :confirmationButton="popup_update_services.confirmation"
      :hint="popup_update_services.hint"
      :message="popup_update_services.message"
      :title="popup_update_services.title"
      :type="popup_update_services.type"
      :services="simpleArray"
      @close="hidePopup()"
      @confirm="
        popup_update_services.callback
          ? popup_update_services.callback
          : hidePopup()
      "
    >
    </PopupUpdateServices>
    <PopupRedirect
      :active.sync="popup_redirect.active"
      :confirmationButton="popup_redirect.confirmation"
      :hint="popup_redirect.hint"
      :message="popup_redirect.message"
      :title="popup_redirect.title"
      :type="popup_redirect.type"
      :queues="queues"
      :ticket="serving_ticket"
      :selectedQueueId="selected_queue.id"
      @refresh="refreshButton"
      @close="hidePopup()"
      @confirm="popup_redirect.callback ? popup_redirect.callback : hidePopup()"
    >
    </PopupRedirect>
    <PopupForwardTo
      :active.sync="popup_forward.active"
      :confirmationButton="popup_forward.confirmation"
      :hint="popup_forward.hint"
      :message="popup_forward.message"
      :title="popup_forward.title"
      :type="popup_forward.type"
      @close="hidePopup()"
      @confirm="popup_forward.callback ? popup_forward.callback : hidePopup()"
    >
    </PopupForwardTo>
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import $ from "jquery";
import Window from "../../components/window/Window.vue";
import Loader from "../../components/loader/Loader.vue";
import {
  counterService,
  queueRequestService,
  queueService,
  businessService
} from "../../_services";
import moment from "moment";
import PopupAddDelay from "../../components/popups/PopupAddDelay.vue";
import PopupUpdateServices from "../../components/popups/PopupUpdateServices.vue";
import PopupRedirect from "../../components/popups/PopupRedirect.vue";
import PopupForwardTo from "../../components/popups/PopupForwardTo.vue";

// var getWaitingListCallBack;
// var getRequestsCallBack;
var countForUserServingTime;
var audio = new Audio("/late-sound2.mp3");

export default defineComponent({
  data: () => ({
    simpleArray: [],
    popup: {
      active: false,
      title: "",
      message: "",
      hint: 0,
      type: "",
      confirmation: "",
      callback: null,
    },
    popup_update_services: {
      active: false,
      title: "",
      message: "",
      hint: 0,
      type: "",
      confirmation: "",
      callback: null,
      services: Array,
    },
    popup_redirect: {
      active: false,
      title: "",
      message: "",
      hint: 0,
      type: "",
      confirmation: "",
      callback: null,
      queues: Array,
    },
    popup_forward: {
      active: false,
      title: "",
      message: "",
      hint: "",
      type: "",
      confirmation: "",
      callback: null,
    },
    selected_queue: {
      id: "",
      totalWaiting: "--",
      pending: "--",
      waiting_hours: 0,
      waiting_minutes: 0,
      waiting_seconds: 0,
      delay: null,
      delay_hours: 0,
      delay_minutes: 0,
      title: "---",
      status: "",
    },
    totalServed: 0,
    queues: [],
    counters: [],
    selected_services: [],
    selected_counter: "",
    waiting_list: {},
    serving_ticket: null,
    loading: true,
    callnext_loading: false,
    user: {},
    teller: {},
    queue_I: false,
    requests: [],
    landscape: false,
    iCalledNext: false,
    client_has_been_in_line_for: 0,
    client_took_more_than_he_should: false,
    ticketsOnHold: [],
    counterAgentStats: {
      ticketServedToday: null,
      ticketServedMonth: null,
      feedbackPositive: null,
      feedbackNegative: null,
      stars: {},
      totalAmount: null,
    },
    amountTicket: null,
    amountTicketSent: null,
    business_config: {},
    paymentTypes: [],
    selectedPaymentType: null
  }),
  mounted() {
    audio.pause();

    if (
      localStorage.getItem("user") == undefined ||
      localStorage.getItem("user") == null ||
      localStorage.getItem("user") == "null"
    ) {
      localStorage.removeItem("user");
      this.$router.push("/login");
    }

    //Get user from localstorage
    this.user = JSON.parse(localStorage.getItem("user")).user;
    this.teller = JSON.parse(localStorage.getItem("user"));
    this.business_config = JSON.parse(localStorage.getItem("user")).business;

    this.UpdateHeights();
    this.selected_queue.id = this.$route.params.id;
    
    this.init_page();
    this.subscribeToTeller();
    
    if (this.$route.params.id != undefined) {
      this.getRequests();
      this.subscribeToPusher();
      if(this.business_config.ticket_price_status == 1) {
        this.getPaymentTypes();
        // get counter agent stats
        businessService
          .getCounterAgentStats()
          .then(
            function(res) {
              // this.counterAgentStats.ticketServedToday = res.queue.today;
              // this.counterAgentStats.ticketServedMonth = res.queue.this_month;
              // this.counterAgentStats.feedbackPositive = res.feedback_note.positive;
              // this.counterAgentStats.feedbackNegative = res.feedback_note.negative;
              // this.counterAgentStats.stars = res.feedback;
              this.counterAgentStats.totalAmount = res.queue.total_amount_today;
            }.bind(this)
          )
          .catch(function(err) {
            console.log(err);
          });
      }
    }
    // this.simpleArray = this.serving_ticket.services;

    if (this.user && this.user.maintenance == 1) {
      this.$router.push("/maintenance");
    }
  },
  components: {
    Window,
    PopupAddDelay,
    PopupUpdateServices,
    PopupRedirect,
    PopupForwardTo,
    Loader,
  },
  methods: {
    getPaymentTypes() {
      businessService.getPaymentTypes().then(function(res) {
      this.paymentTypes = res.data;
    }.bind(this))
    },
    voiceTeller(id) {
      queueService.displayCall(id);
    },
    callNextWithPrice() {
      const self = this;
      if (
        this.serving_ticket &&
        this.serving_ticket.status === "inservice" &&
        !self.amountTicketSent
      ) {
        this.$confirm({
          message: self.$t('Teller.CallWithoutPrice'),
          button: {
            no: self.$t('Teller.Cancel'),
            yes: self.$t('Teller.Yes'),
          },
          /**
           * Callback Function
           * @param {Boolean} confirm
           */
          callback: (confirm) => {
            if (confirm) {
              self.nextButton();
            }
          },
        });
      } else {
        self.nextButton();
      }
    },
    closeCounter(counter_id = 0, queue = {}) {
      const self = this;
      this.$confirm({
        message: self.$t('Teller.CloseCounter'),
        button: {
          no: self.$t('Teller.Cancel'),
          yes: self.$t('Teller.Yes'),
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            self.selected_counter = "";
            self.callnext_loading = true;
            var data = {};
            if (counter_id == 0) {
              data = {
                queue: self.$route.params.id,
                counter_id: localStorage.getItem("counter"),
              };
            } else {
              data = {
                queue: queue.queue.id,
                counter_id: counter_id,
              };
            }
            // alert(JSON.stringify(data))

            counterService
              .close(data)
              .finally(function() {
                self.callnext_loading = false;
              })
              .finally(function() {
                self.$router.push("/waitinglist");
              });
          }
        },
      });
    },
    closeCounter2() {
      this.selected_counter = "";
    },
    init_page(checkInServeFromOtherCounter = false) {
      const self = this;
      this.loading = true;

      clearInterval(countForUserServingTime);
      audio.pause();
      this.client_has_been_in_line_for = 0;
      this.client_took_more_than_he_should = false;

      if (this.selected_queue.id != undefined) {
        queueService.getAllWithSummary().then(
          function(data) {
            this.queues = data.queues;
          }.bind(this)
        );
        
        this.loadWaitingList(checkInServeFromOtherCounter);
        queueService
          .getByIdWithSummary(this.selected_queue.id)
          .then(function(data) {
            self.selected_queue = data;
            self.selected_queue.id = data.queue_id;
            self.selected_queue.title =
              data.queue.title.length < 20
                ? data.queue.title
                : data.queue.title.substring(0, 19) + "...";

            //Auto selected counter
            data.queue.counters.map((obj) => {
              if (obj.id == localStorage.getItem("counter")) {
                self.selected_counter = obj;
              }
            });
            self.selected_queue.waiting_hours = self.getHours(
              data.waiting_time
            );
            self.selected_queue.waiting_minutes = self.getMinutes(
              data.waiting_time
            );
            self.selected_queue.waiting_seconds = self.getSeconds(
              data.waiting_time
            );

            self.countDown();
          })
          .finally(function() {
            self.loading = false;
          });
      } else {
        queueService
          .getAllWithSummary()
          .then(function(data) {
            console.log(data);
            self.queues = data.queues;
            if (data.queues.length == 1) {
              console.log(data.queues[0]);
              self.select_queue(
                data.queues[0].queue.id,
                data.queues[0].queue.counters.length,
                data.queues[0].queue.counters
              );
              // self.selected_queue= data.queues[0];
              // self.selected_queue.id= data.queues[0].queue_id;
              // self.selected_queue.title= data.queues[0].queue.title;
              // self.selected_queue.title= (data.queues[0].queue.title.length<20)?data.queues[0].queue.title:data.queues[0].queue.title.substring(0, 19)+'...'

              // //Auto selected counter
              // data.queues[0].queue.counters.map((obj) => {
              //     if(obj.id==localStorage.getItem('counter')){
              //         self.selected_counter= obj;
              //     }
              // })
            }
          })
          .finally(function() {
            self.loading = false;
          });
      }
      // this.getOnHoldTickets();
    },
    showAlert(message, callback, ticket_id) {
      var answer = confirm(message);
      if (answer) {
        if (callback == "onHold") {
          this.onHold();
        } else if (callback == "recallOnHold") {
          this.recallOnHold(ticket_id);
        }
      }
    },
    onHold() {
      queueService
        .onHold(this.$route.params.id, this.serving_ticket.ticket_id)
        .then(function(data) {
          console.log("on hold: " + data);
        })
        .finally(
          function() {
            this.init_page();
          }.bind(this)
        );
    },
    recallOnHold(ticket_id) {
      queueService
        .recallOnHold(this.$route.params.id, ticket_id)
        .then(function(data) {
          console.log("on hold: " + data);
        })
        .finally(
          function() {
            this.init_page();
          }.bind(this)
        );
    },
    getOnHoldTickets() {
      if (this.$route.params.id) {
        queueService
          .getOnHoldTickets(this.$route.params.id)
          .then(
            function(data) {
              //      console.log("ticket on hold: " + data.totalWaiting);
              //this.ticketsOnHold = data.waiting_list;
              this.ticketsOnHold = [];
              data.waiting_list.map((el) => {
                let ticket = {
                  public_identifier: el.public_identifier,
                  ticket_id: el.ticket_id,
                };
                console.log("TOH: " + el.ticket_id);
                this.ticketsOnHold.push(ticket);
              });
            }.bind(this)
          )
          .finally(function() {});
        console.log("length TOH: " + this.ticketsOnHold.length);
      }
    },
    hidePopup() {
      this.popup = {
        active: false,
        title: "",
        message: "",
        hint: "",
        type: "",
        confirmation: "",
        callback: null,
      };
      this.popup_update_services = {
        active: false,
        title: "",
        message: "",
        hint: "",
        type: "",
        confirmation: "",
        callback: null,
      };
      this.popup_redirect = {
        active: false,
        title: "",
        message: "",
        hint: "",
        type: "",
        confirmation: "",
        callback: null,
      };
      this.popup_forward = {
        active: false,
        title: "",
        message: "",
        hint: "",
        type: "",
        confirmation: "",
        callback: null,
      };
    },
    ShowPopupForwardTo() {
      this.showPopup(
        "success",
        "Transferer ce client à :",
        "",
        "",
        "Ajouter un délai",
        this.hidePopup,
        "forward"
      );
    },
    addDelay() {
      this.showPopup(
        "success",
        this.$t('Teller.ChangeTimeService'),
        "",
        this.serving_ticket.ticket_id,
        this.$t('Teller.ConfirmButton'),
        this.hidePopup,
        "delay"
      );
    },
    redirectTicket() {
      this.showPopup(
        "success",
        this.$t('Teller.TransferTicket'),
        "",
        this.serving_ticket.ticket_id,
        this.$t('Teller.ConfirmButton'),
        this.hidePopup,
        "redirect"
      );
    },
    UpdateServicePopup() {
      this.showPopup(
        "success",
        "Modifier ou ajouter un service",
        "",
        this.serving_ticket.ticket_id,
        "Confirmer",
        this.hidePopup,
        "update"
      );
      //  this.showPopupUpdateServices("success", "Modifier ou ajouter un service", '', '', 'Confirmer', this.hidePopup);
    },
    showPopup(type, title, message, hint, confirmation, callback, popupType) {
      //ned to be turned into switch statement
      if (popupType == "delay") {
        this.popup = {
          active: true,
          title: title,
          message: message,
          hint: hint,
          type: type,
          confirmation: confirmation,
          callback: callback,
        };
      } else if (popupType == "update") {
        this.serving_ticket.services.map((el) => {
          console.log("id element services");
          console.log(el.id);
          this.simpleArray.push(el.id);
        });
        console.log(this.simpleArray.length);
        this.popup_update_services = {
          active: true,
          title: title,
          message: message,
          hint: hint,
          type: type,
          confirmation: confirmation,
          callback: callback,
          services: this.simpleArray,
        };
      } else if (popupType == "redirect") {
        this.popup_redirect = {
          active: true,
          title: title,
          message: message,
          hint: hint,
          type: type,
          confirmation: confirmation,
          callback: callback,
          services: this.queues,
        };
      } else {
        this.popup_forward = {
          active: true,
          title: title,
          message: message,
          hint: hint,
          type: type,
          confirmation: confirmation,
          callback: callback,
        };
      }
    },

    getRequests(id = null) {
      const self = this;
      queueRequestService
        .getAll(id ? id : this.$route.params.id)
        .then(function(data) {
          self.requests = data.requests.map((obj) => {
            obj.services = obj.service_names.split(",");
            obj.from_now = moment(obj.created_at).fromNow();
            return obj;
          });
          self.selected_queue.pending = self.requests.length;
          console.log(self.requests);
        });
    },
    optionsButton() {
      $(".footer").hide();
      $(".window-component").slideDown("500");
    },
    refreshButton() {
      this.$router.go();
    },
    nextButton() {
      clearInterval(countForUserServingTime);
      audio.pause();
      this.client_has_been_in_line_for = 0;
      this.client_took_more_than_he_should = false;

      this.callnext_loading = true;
      this.iCalledNext = true;
      const self = this;
      // console.log(this.selected_queue.id);
      queueService
        .callNext(this.selected_queue.id)
        .then(function(data) {
          // console.log("call next : ");
          self.serving_ticket = data.customer;
          /*
        if(self.serving_ticket && self.serving_ticket.status=='inservice'){
            $('.ticket').slideUp("slow")
        }else{

        }
        */
        })
        .catch(function(ex) {
          console.log(ex);
          if (ex == "counter_error") {
            self.$confirm({
              message: self.$t('Teller.OpenSameCounter'),
              button: {
                yes: self.$t('Teller.Close'),
              },
              /**
               * Callback Function
               * @param {Boolean} confirm
               */
              callback: (confirm) => {
                if (confirm) {
                  localStorage.removeItem("counter");
                  self.selected_counter = "";
                  self.callnext_loading = true;
                  window.location.href = "/waitinglist";
                }
              },
            });
          } else {
            $(".ticket").slideUp("slow", function() {
              self.selected_queue.totalWaiting = 0;
              self.init_page();
              $(".ticket").slideDown("slow");
            });
          }
        })
        .finally(function() {
          self.callnext_loading = false;
          self.amountTicketSent = null;
          self.selectedPaymentType = null;
        });
    },
    absentButton() {
      if (this.serving_ticket && this.serving_ticket.status === "inservice") {
        this.loading = true;
        // queueService.revokeTicket(this.selected_queue.id, this.waiting_list[0].id).then(function(data){
        //     console.log(data);
        //     // this.init_page()
        // }.bind(this)).finally(function(){
        //     this.loading= false
        // }.bind(this));
        let data = {
          queue_line_id: this.selected_queue.id,
          public_identifier: this.serving_ticket.public_identifier,
          rank: 1,
        };
        queueService
          .absent(this.selected_queue.id, data)
          .then(
            function(data) {
              //this.init_page();
              this.serving_ticket = data.customer;
            }.bind(this)
          )
          .catch(
            function(err) {
              console.log(err);
            }.bind(this)
          )
          .finally(
            function() {
              // this.init_page();
              this.loading = false;
            }.bind(this)
          );
      }
    },
    fermerButton() {
      this.loading = true;
      queueService
        .endTicket(
          this.selected_queue.id,
          this.serving_ticket.public_identifier
        )
        .then(
          function() {
            this.init_page();
            console.log("Closed");
          }.bind(this)
        )
        .finally(
          function() {
            this.loading = false;
          }.bind(this)
        );
    },
    UpdateHeights() {
      this.landscape = $(window).width() > $(window).height();
      if (this.landscape) {
        $(".right-side .btn").height(($(window).height() - 46) * 0.2);
        $(".left-side .btn").height(($(window).height() - 46) * 0.25);
        $(".queues").height("");
      } else {
        $(".first-side").height(($(window).height() - 46) * 0.65);
        $(".first-side").css("overflow-y", "scroll");
        $(".first-side").css("margin-bottom", ($(window).height() - 46) * 0.06);
        $(".queues").height(($(window).height() - 46) * 0.65);
        $(".left-side .btn").height(($(window).height() - 46) * 0.1);
        $(".right-side .btn").height(($(window).height() - 46) * 0.1);
      }

      // $('.queues').height( ($(window).height()-46)-$('.logo').height() )
    },
    select_queue(id, counters_length, counters) {
      if (counters_length < 1) {
        if (counters_length == 1) {
          this.selected_counter = counters[0];
        }
        this.$router.push("waitinglist/" + id);
        this.selected_queue.id = id;
        this.loading = true;
        this.init_page();
        // this.loadWaitingList();
        this.subscribeToPusher(id);
        this.getRequests(id);
      } else {
        if ($(".queue-item").length >= 1) {
          $(".queue-item:not(.queue-item-" + id + ")").slideUp(
            "slow",
            function() {
              $(".counters").hide();
              $(".counters-" + id).show();
            }
          );
        } else {
          $(".counters").hide();
          $(".counters-" + id).show();
        }
      }
    },
    getHours(seconds) {
      let h = Math.floor(seconds / 3600);
      return h;
    },
    getMinutes(seconds) {
      let m = Math.floor((seconds % 3600) / 60);
      return m;
    },
    getSeconds(seconds) {
      let s = Math.floor((seconds % 3600) % 60);
      return s;
    },
    loadWaitingList() {
      this.loading = true;
      const self = this;
      queueService
        .getWaitingList(this.selected_queue.id)
        .then(function(data) {
          // console.log("waiting list:");
          // console.log(data);
          self.totalServed = data.totalServed;
          // console.log('_________', cancelUpdate,  self.serving_ticket.status=='inservice')
          // if(!(cancelUpdate && self.serving_ticket && self.serving_ticket.status=='inservice' && !self.iCalledNext)){
          // console.log("_________", "updated");
          // alert(self.selected_counter+'a')

          self.serving_ticket = data.waiting_list.find((obj) => {
            if (
              obj &&
              obj.status == "inservice" &&
              obj.counter_id == localStorage.getItem("counter")
            ) {
              return true;
            }

            if (obj && obj.status != "inservice") {
              return true;
            }
          });

          // this.selected_services = self.waiting_list[0].services;
          //Init time spent and status
          self.client_has_been_in_line_for = 0;
          self.client_took_more_than_he_should = false;

          countForUserServingTime = setInterval(function() {
            if (
              self.serving_ticket &&
              self.serving_ticket.status == "inservice"
            ) {
              self.client_has_been_in_line_for++;
              if (
                self.client_has_been_in_line_for >
                self.serving_ticket.estimatedTimeToSpend * 60
              ) {
                self.client_took_more_than_he_should = true;
                if (self.client_has_been_in_line_for % 10 == 0) {
                  audio.play();
                }
              }
            } else {
              self.client_has_been_in_line_for = 0;
              self.client_took_more_than_he_should = false;
              clearInterval(countForUserServingTime);
              audio.pause();
            }
          }, 1000);

          if (
            self.serving_ticket &&
            self.serving_ticket.status &&
            self.serving_ticket.status == "inservice"
          ) {
            $(".ticket").slideDown("slow");
          }
          // }
          self.iCalledNext = false;
          // }
        })
        .finally(function() {
          self.callnext_loading = false;
        });
    },
    confirmCounter(queue) {
      const self = this;
      this.loading = true;
      console.log('azerty : ', queue.queue.id)

      counterService
        .open(queue.queue.id, this.selected_counter)
        .then(function() {
          // console.log(data);
          localStorage.setItem(
            "counter",
            JSON.stringify(self.selected_counter)
          );

          self.$router.push("waitinglist/" + queue.queue.id);
          self.selected_queue = queue;
          self.selected_queue.id = queue.queue.id;
          // self.init_page();
          // self.loadWaitingList();
        })
        .catch(function() {
          self.loading = false;
          alert(self.$t('Teller.CounterAlreadyOpen'));
        })
        .finally(function() {
          self.subscribeToPusher(queue.queue.id);
          // self.getRequests(queue.queue.id);
        });
    },
    countDown() {
      const self = this;
      // if(delay!=null){
      // delay.delay*= 60;
      // let end= moment();
      // var seconds_diff = moment.duration(end.diff(delay.created_at)).asSeconds();

      // //Delay left
      // self.selected_queue.delay.delay=  delay.delay-seconds_diff;

      // let delay_I= setInterval(() => {
      //     if(self.selected_queue.delay.delay>0 && delay){
      //         self.selected_queue.delay.delay--;
      //         self.selected_queue.delay_hours= Math.floor(self.selected_queue.delay.delay/3600);
      //         self.selected_queue.delay_minutes= Math.floor((self.selected_queue.delay.delay%3600)/60);
      //         self.selected_queue.delay_secondes= Math.floor((self.selected_queue.delay.delay%3600)%60);
      //     }else{
      //         clearInterval(delay_I)
      //         self.resumeDelay();
      //     }
      // }, 1000);
      // }else
      if (
        this.serving_ticket &&
        this.serving_ticket.status == "inservice" &&
        !this.queue_I
      ) {
        this.queue_I = setInterval(() => {
          if (self.selected_queue.waiting_time > 0) {
            self.selected_queue.waiting_time--;
            self.selected_queue.waiting_hours = self.getHours(
              self.selected_queue.waiting_time
            );
            self.selected_queue.waiting_minutes = self.getMinutes(
              self.selected_queue.waiting_time
            );
            self.selected_queue.waiting_seconds = self.getSeconds(
              self.selected_queue.waiting_time
            );
          } else {
            clearInterval(self.queue_I);
            self.queue_I = false;
          }
        }, 1000);
      }
    },
    resumeDelay() {
      const self = this;
      this.loading = true;

      queueService
        .resumeService(this.selected_queue.id)
        .then(function() {
          // console.log(data);
        })
        .finally(function() {
          $(".window-component").slideUp("500");

          self.init_page();
        });
    },
    subscribeToPusher(id = null) {
      const self = this;
      this.$pusher
        .channel("private-queue-" + (id ? id : this.$route.params.id))
        .listen(".queue-refresh-data", function(data) {
          console.log("data__________________");
          console.log(data);
          self.selected_queue.totalWaiting = data.totalWaiting;
          self.selected_queue.waiting_time = data.waiting_time;
          // get waiting time & convert it
          self.selected_queue.waiting_hours = self.getHours(data.waiting_time);
          self.selected_queue.waiting_minutes = self.getMinutes(
            data.waiting_time
          );
          self.selected_queue.waiting_seconds = self.getSeconds(
            data.waiting_time
          );
          // get agentStat only when call is fired
          // if (data.origin == "call") {
          //   self.counterAgentStats.ticketServedToday = data.served_today;
          //   self.counterAgentStats.ticketServedMonth = data.served_this_month;
          // }
          setTimeout(() => {
            self.countDown();
          }, 500);

          if (
            !self.serving_ticket &&
            data.waiting_ticket &&
            data.waiting_ticket.status == "waiting"
          ) {
            self.serving_ticket = data.waiting_ticket;
          }
        });

        self.$pusher
        .channel("private-teller-" + self.user.id)
        .listen(".teller-refresh-data", function(data) {
          console.log("___teller-refresh-data: ", data);
          self.counterAgentStats.ticketServedToday = data.served_today;
          self.counterAgentStats.ticketServedMonth = data.served_this_month;
          self.counterAgentStats.totalAmount = data.total_amount_today;
        });
    },
    subscribeToTeller() {
      const self = this;
      self.$pusher.channel("private-teller-" + self.user.id).listen(
        ".maintenance-change",
        function(data) {
          console.log("___teller: ", data);
          self.teller.user.maintenance = data.status;
          localStorage.setItem("user", JSON.stringify(self.teller));
          if (data.status == 1) {
            console.log("inside waitinglist");
            self.$router.push("/maintenance");
          }
        }
      );
    },
    unsubscribeFromPusher(id) {
      this.$pusher
        .channel("private-queue-" + (id ? id : this.$route.params.id))
        .stopListening(".teller-refresh-data");
      // this.$pusher.unsubscribe("private-queue-" + this.$route.params.id);
      // this.$pusher.unbind("ticket-created", getWaitingListCallBack);
      // this.$pusher.unbind("ticket-deleted", getWaitingListCallBack);
      // this.$pusher.unbind("queue-request-created", getRequestsCallBack);
      // this.$pusher.unbind("queue-request-deleted", getRequestsCallBack);
    },
    openRequestsPopup() {
      this.$refs.windowComponent.showPopupRequests(
        "success",
        "Demandes en attente",
        "",
        "",
        "Demandes en attente"
      );
    },
    backToQueues() {
      this.$router.push("/waitinglist");
      this.$router.go();
      this.selected_queue.id = undefined;
      this.selected_counter = "";
      this.init_page();
    },
    updateTicketPrice() {
      const self = this;
      if (self.amountTicket != null && self.selectedPaymentType != null) {
      let payload = {
        price: self.amountTicket,
        type_payment: self.selectedPaymentType
      };
      self.loading = true;
      queueService
        .updateTicketPrice(
          self.selected_queue.id,
          self.serving_ticket.ticket_id
            ? self.serving_ticket.ticket_id
            : self.serving_ticket.id,
          payload
        )
        .then(function() {
          self.amountTicketSent = self.amountTicket;
          self.amountTicket = null;
          self.selectedPaymentType = null;
          self.$confirm({
            message: self.$t('Teller.TicketPriceSaved'),
            button: {
              no: self.$t('Teller.Close'),
            },
          });
        })
        .finally(function() {
          self.loading = false;
        });
      }
      else {
        self.$confirm({
            message: self.$t('Teller.EnterAmountError'),
            button: {
              no: self.$t('Teller.Close'),
            },
          });
      }
    },
  },
  created() {
    window.addEventListener("resize", this.UpdateHeights);
  },
  beforeDestroy() {
    this.unsubscribeFromPusher(this.$route.params.id);
    // counterService.close(
    //   this.$route.params.id,
    //   localStorage.getItem("counter")
    // );
  },
  async accept() {
    this.showUpdateUI = false;
    await this.$workbox.messageSW({ type: "SKIP_WAITING" });
  },
});
</script>

<style scoped>
.late-danger {
  margin-left: 10px;
}

.voice-teller {
  float: left;
  border: 2px solid #fff;
  padding: 5px 8px;
  border-radius: 50px;
  cursor: pointer;
  transition: 0.5s;
  margin-left: 10px;
  margin-top: -10px;
  position: absolute;
}

.redirect-teller {
  float: right;
  border: 2px solid #fff;
  padding: 5px 8px;
  border-radius: 50px;
  cursor: pointer;
  transition: 0.5s;
  margin-left: -5vw;
  margin-top: -10px;
  margin-right: 10px;
}

.voice-teller:hover {
  background: #fff;
  color: #29a1d3;
}
</style>

<template>
  <div v-if="isActive" id="PopupUpdateServices" class="loading-wrapper">
    <Loader v-if="loading" id="page-loader"></Loader>
    <div :class="'loading-popup popup-' + type">
      <div class="h-100 m-5 align-items-center text-center m-0">
        <div class="row">
          <div class="col align-items-center">
            <!-- <div class="float-left">
                <img v-if="type == 'success'" class="icon" :src="require('@/assets/images/check-circle.png')" />
                <img v-if="type == 'danger'" class="icon" :src="require('@/assets/images/danger.png')" />
            </div> -->
            <div class="closeDelay" @click="closeDelayPopup">
              <font-awesome-icon :icon="['fa', 'times']" class="times" />
            </div>

            <div class="text-center d-inline-block mb-5">
              <h2 class="popup-title">{{ title }}</h2>
              <hr />
              <div class="selector">
                <label class="typo__label" style="margin: 0 18px;">{{$t('Teller.SelectedCounter')}}</label>
                <select class="ml-3 w-70" v-model="selected_queue">
                  <option
                    :key="index"
                    :value="item.queue.id"
                    :disabled="item.status == 0"
                    v-for="(item, index) in queues"
                  >
                    {{ item.queue.title }}
                  </option>
                </select>
                <br />
                <br />
                <span style="font-weight: bold">{{ $t('Teller.KeepTicketId') }}</span>
                <input
                  type="checkbox"
                  class="checkbox ml-3"
                  v-model="keepTicket"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-if="hint" class="text-center mt-4">
          <!-- <span class="hint">{{ hint }}</span> -->
        </div>

        <button class="btn-confirm" @click="redirectCustomer()">
          {{ confirmationButton }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { queueService } from "../../_services";
import Loader from "../loader/Loader.vue";

export default {
  name: "LoadingPopup",
  data: () => ({
    keepTicket: false,
    selected_queue: null,
    decrementButton: false,
    loading: false,
  }),
  props: {
    active: Boolean,
    title: String,
    message: String,
    hint: [Number, String],
    type: String,
    confirmationButton: String,
    queues: Array,
    ticket: Object,
    selectedQueueId: [String, Number],
  },
  methods: {
    redirectCustomer() {
      this.loading = true;

      let ticket = {
        queue_id: this.selectedQueueId,
        customer_id: this.ticket.customer_id ? this.ticket.customer_id : this.ticket.id,
        target_id: this.selected_queue,
        keepTicket: this.keepTicket ? 1 : 0,
      };
      queueService
        .redirect(ticket)
        .then(function () {}.bind(this))
        .catch(function () {}.bind(this))
        .finally(
          function () {
            this.loading = false;
            this.closeDelayPopup();
            this.$emit("refresh");
          }.bind(this)
        );
    },
    closeDelayPopup() {
      this.$emit("close");
    },
  },
  computed: {
    isActive() {
      return this.active;
    },
  },
  components: {
    Loader,
  },
};
</script>

<style lang="scss" scoped>
.loading-wrapper {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.loading-popup {
  width: 60%;
  max-width: 500px;
  min-width: 300px;
  background: #ffffff;
  border-radius: 20px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  color: #264076;
}

.loader {
  width: 50px;
  height: 50px;
}

.popup-success .popup-title {
  color: #bed24c !important;
  margin-bottom: 0px;
  font-size: 20px !important;
  display: flex;
}

.popup-success .icon {
  width: 70px;
  margin: 10px 10px;
}

.popup-success .message {
  color: #444;
  font-weight: lighter;
  font-size: 18px;
}

.popup-success .hint {
  color: #333;
  font-weight: bold;
  font-size: 16px;
}

.popup-success .btn-confirm {
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 0px 0px 20px 20px;
  padding: 15px;
  width: 100%;
  border: none;
  background: #bed24c;
  color: #fff;
  font-weight: 400;
  font-size: 22px;
}

.popup-success .btn-confirm:not(:disabled):not(.disabled):active,
.btn-confirm:not(:disabled):not(.disabled).active,
.show > .btn-confirm.dropdown-toggle {
  color: #fff;
  background-color: #bed24c;
  border-color: #bed24c;
}

.popup-success .btn-confirm:focus,
.btn-confirm.focus {
  box-shadow: none;
}

.popup-danger .popup-title {
  color: #dc3545 !important;
  margin-bottom: 0px;
  font-size: 42px !important;
  display: flex;
}

.popup-danger .icon {
  width: 70px;
  margin: 10px 10px;
}

.popup-danger .message {
  color: #444;
  font-weight: lighter;
  font-size: 18px;
}

.popup-danger .hint {
  color: #333;
  font-weight: bold;
  font-size: 16px;
}

.popup-danger .btn-confirm {
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 0px 0px 20px 20px;
  padding: 15px;
  width: 100%;
  border: none;
  background: #dc3545;
  color: #fff;
  font-weight: 400;
  font-size: 22px;
}

.popup-danger .btn-confirm:not(:disabled):not(.disabled):active,
.btn-confirm:not(:disabled):not(.disabled).active,
.show > .btn-confirm.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.popup-danger .btn-confirm:focus,
.btn-confirm.focus {
  box-shadow: none;
}

.delay {
  padding: 30px 0;
  margin-top: -16px;
}

.delay .time {
  width: 100%;
  height: 50px;
  border: 1px solid #264076;
  padding: 0 10px;
  border-radius: 10px;
}

.delay .checkbox {
  display: inline-block;
  margin: 20px 0;
}

.delay textarea {
  display: block;
  height: 70px;
  width: 100%;
  border: 1px solid #264076;
  padding: 0 10px;
  border-radius: 10px;
}

.closeDelay {
  float: right;
  font-size: 17px;
  color: #bed24c;
  padding: 5px 10px;
  border: 1px solid #bed24c;
  border-radius: 50px;
  transition: 0.3s;
  position: absolute;
  right: 0;
}

.closeDelay:hover {
  color: #fff;
  background-color: #bed24c;
}
</style>